<template>
  <div class="quiz-container">
    <h1>Choose Your Offer</h1>
    <div class="offer-gallery" v-if="!selectedOffer">
      <div 
        class="offer-card" 
        v-for="offer in offers" 
        :key="offer.id"
        @click="showOfferDetails(offer)"
      >
        <div class="offer-image">
          <img :src="offer.image" :alt="offer.title">
        </div>
        <div class="offer-info">
          <h3>{{ offer.title }}</h3>
          <p>{{ offer.description }}</p>
          <button class="btn-primary">Select This Offer</button>
        </div>
      </div>
    </div>

    <div class="offer-details" v-else>
      <button class="btn-back" @click="selectedOffer = null">
        ← Back to Offers
      </button>
      
      <h2>{{ selectedOffer.title }}</h2>
      <div class="offer-content">
        <div class="offer-main-info">
          <div class="offer-image-large">
            <img :src="selectedOffer.image" :alt="selectedOffer.title">
          </div>
          
          <div class="offer-description">
            <h3>About This Offer</h3>
            <p>{{ selectedOffer.fullDescription }}</p>
          </div>
        </div>

        <div class="offer-resources">
          <div class="script-section">
            <h3>Sample Script</h3>
            <div class="script-content">
              <p>{{ selectedOffer.script }}</p>
            </div>
          </div>
          
          <div class="stock-footage-section">
            <h3>Available Stock Footage</h3>
            <div class="footage-grid">
              <div v-for="(footage, index) in selectedOffer.stockFootage" 
                   :key="index" 
                   class="footage-item">
                <iframe
                  :src="footage.url"
                  allow="autoplay"
                  allowfullscreen>
                </iframe>
                <p class="footage-description">{{ footage.description }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="action-buttons">
          <button class="btn-primary" @click="openSubmissionModal">
            Submit Your Work
          </button>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal" v-if="showSubmissionModal">
      <div class="modal-content">
        <h3>Submit Your Work</h3>
        <p>Please follow these steps:</p>
        <ol>
          <li>Upload your video to Google Drive</li>
          <li>Set sharing permissions to "Anyone with the link can edit"</li>
          <li>Copy the sharing link and paste it below</li>
        </ol>
        <div class="form-group">
          <label for="videoUrl">Video URL</label>
          <input 
            type="url" 
            id="videoUrl" 
            v-model="submissionUrl"
            placeholder="Paste your Google Drive video URL here"
            required
          >
        </div>
        <div class="modal-buttons">
          <button class="btn-secondary" @click="closeSubmissionModal">Cancel</button>
          <button 
            class="btn-primary" 
            @click="submitWork" 
            :disabled="!submissionUrl"
          >
            Submit for Review
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  name: 'OfferSelection',
  props: {
    userData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedOffer: null,
      showSubmissionModal: false,
      submissionUrl: '',
      emailjsConfig: {
        serviceId: 'service_7u75awt',
        templateId: 'template_l9dw5pp',
        publicKey: 'blvwOGNvD4sRPqGjC'
      },
      offers: [
        {
          id: 1,
          title: 'Window Replacement',
          description: 'Create a compelling video for window replacement services',
          image: 'https://sunluxroofwindows.co.uk/blog/wp-content/uploads/2023/09/replacement-windows-glass-only.jpg',
          fullDescription: 'Window replacement services offer a unique opportunity to create impactful video content. This offer focuses on energy efficiency, home improvement, and cost savings. Your task will be to create a video that emphasizes the benefits of modern windows while addressing common homeowner pain points.',
          script: 'Stop! Don\'t let your money float away through old windows. These windows aren\'t just outdated — they\'re making your energy bills higher. Get new windows today and see the change: lower energy costs, a warmer home, and a great new look. Don\'t wait. Call now for a free energy savings check.',
          stockFootage: [
            {
              url: 'https://drive.google.com/file/d/1pdamgpoyIvkJHwwcs0WuCD9zXo9iqFG6/preview',
              description: 'Old windows with visible drafts'
            },
            {
              url: 'https://drive.google.com/file/d/1zOxZ4z0r8w1tGN22u29EJK4vIvZskeK7/preview',
              description: 'Modern energy-efficient windows'
            },
            {
              url: 'https://drive.google.com/file/d/1od3AK54Y9u3bppdJcnxeAGaXev8IRFXn/preview',
              description: 'Installation process'
            }
          ]
        },
        {
          id: 2,
          title: 'Roofing Services',
          description: 'Develop an engaging video for roofing services',
          image: 'https://planhub.com/wp-content/uploads/2022/08/How-to-Bid-a-Metal-Roofing-Job.jpg',
          fullDescription: 'Roofing services represent a critical home maintenance need that requires immediate attention when problems arise. This offer focuses on protection, safety, and home value preservation. Your video will need to create urgency while highlighting the importance of professional roofing services.',
          script: 'Storm season is here. Can your roof handle it? Damaged shingles and leaks might seem small now, but they can turn into expensive problems fast. Upgrade to a roof that\'s built to last through any weather. Keep your family safe and your home protected. Don\'t wait for the next storm to find out. Call now for a free roof check.',
          stockFootage: [
            {
              url: 'https://drive.google.com/file/d/1L1YZbPCEeI4RpTDhIp9wqfee1zcX_0pQ/preview',
              description: 'Storm damage assessment'
            },
            {
              url: 'https://drive.google.com/file/d/163IUmx1855kiqEh9yTtl98uBnnJo_TB6/preview',
              description: 'Professional roof inspection'
            },
            {
              url: 'https://drive.google.com/file/d/1jeSlZiFpzltWCAfACvidUlbV9uHTcuzQ/preview',
              description: 'Roof repair process'
            },
            {
              url: 'https://drive.google.com/file/d/1C2jwhqE36aQ9Y--LQIbKB6mQw1EKUKu_/preview',
              description: 'Complete roof installation'
            },
            {
              url: 'https://drive.google.com/file/d/1C5jiNqfmiXMIn5MleDUHHuv2lJuDN3_z/preview',
              description: 'Quality inspection'
            },
            {
              url: 'https://drive.google.com/file/d/1hB1Hlx-iQVcFKeQuKQDdaIuJe0gjNMu5/preview',
              description: 'Final results showcase'
            },
            {
              url: 'https://drive.google.com/file/d/1TBnXNyace31QzbeofejIj1mf0FyM90Qh/preview',
              description: 'Customer testimonial'
            }
          ]
        }
      ]
    }
  },
  methods: {
    showOfferDetails(offer) {
      this.selectedOffer = offer
    },
    openSubmissionModal() {
      this.showSubmissionModal = true
    },
    closeSubmissionModal() {
      this.showSubmissionModal = false
      this.submissionUrl = ''
    },
    submitWork() {
      if (!this.submissionUrl) return
      
      if (!this.submissionUrl.includes('drive.google.com')) {
        alert('Please provide a valid Google Drive URL')
        return
      }
      
      const templateParams = {
        to_name: 'Zaur',
        from_name: this.userData.name,
        from_email: this.userData.email,
        message: `
          New Video Production Submission
          
          User Details:
          Name: ${this.userData.name}
          Email: ${this.userData.email}
          
          Submission Details:
          Offer: ${this.selectedOffer.title}
          Video URL: ${this.submissionUrl}
          Submission Date: ${new Date().toLocaleString()}
        `,
      }

      emailjs.send(
        this.emailjsConfig.serviceId,
        this.emailjsConfig.templateId,
        templateParams,
        this.emailjsConfig.publicKey
      )
      .then(() => {
        alert('Your work has been submitted successfully!')
        this.$emit('work-submitted', {
          offer: this.selectedOffer,
          submissionUrl: this.submissionUrl
        })
        this.closeSubmissionModal()
      })
      .catch((error) => {
        console.error('EmailJS Error:', error)
        alert('There was an error submitting your work. Please try again.')
      })
    }
  }
}
</script>

<style scoped>
.offer-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin: 20px 0;
}

.offer-card {
  background-color: rgba(106, 90, 205, 0.1);
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.offer-card:hover {
  transform: translateY(-5px);
}

.offer-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.offer-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.offer-info {
  padding: 20px;
}

.offer-details {
  margin: 20px 0;
}

.btn-back {
  background: none;
  border: none;
  color: var(--accent-purple);
  cursor: pointer;
  font-size: 16px;
  padding: 10px 0;
  margin-bottom: 20px;
}

.offer-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.offer-main-info {
  margin-bottom: 40px;
}

.offer-image-large {
  width: 100%;
  height: 400px;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 30px;
}

.offer-image-large img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.offer-description {
  background-color: rgba(106, 90, 205, 0.05);
  padding: 25px;
  border-radius: 12px;
}

.offer-resources {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.script-section {
  background-color: rgba(106, 90, 205, 0.1);
  padding: 25px;
  border-radius: 12px;
}

.stock-footage-section {
  background-color: rgba(106, 90, 205, 0.1);
  padding: 25px;
  border-radius: 12px;
}

.footage-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.action-buttons {
  margin-top: 30px;
  text-align: center;
}

.btn-primary {
  min-width: 200px;
}

h2 {
  text-align: center;
  margin-bottom: 30px;
  color: var(--accent-purple);
}

h3 {
  color: var(--accent-purple);
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .offer-content {
    padding: 15px;
  }

  .offer-image-large {
    height: 300px;
  }

  .footage-grid {
    grid-template-columns: 1fr;
  }
}

.footage-item {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.footage-item iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #1a1a1a;
  padding: 30px;
  border-radius: 12px;
  max-width: 500px;
  width: 90%;
  min-height: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.modal-content h3 {
  margin-bottom: 20px;
  color: #ffffff;
}

.modal-content p,
.modal-content li {
  color: #ffffff;
  margin-bottom: 10px;
  line-height: 1.4;
}

.form-group {
  margin: 20px 0;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #ffffff;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  background-color: #2a2a2a;
  color: var(--primary-text);
  border-radius: 4px;
}

.form-group input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 20px;
}

.btn-secondary {
  background: none;
  border: 1px solid var(--accent-purple);
  color: var(--accent-purple);
  padding: 8px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-secondary:hover {
  background-color: rgba(106, 90, 205, 0.1);
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style> 