<template>
  <div class="quiz-container">
    <registration-form v-if="currentStep === 'registration'" @registration-complete="handleRegistration" />
    <case-study v-if="currentStep === 'caseStudy'" @continue="currentStep = 'offerSelection'" />
    <offer-selection 
      v-if="currentStep === 'offerSelection'" 
      :user-data="userData"
      @offer-selected="handleOfferSelection" 
    />
  </div>
</template>

<script>
import RegistrationForm from './RegistrationForm.vue'
import CaseStudy from './CaseStudy.vue'
import OfferSelection from './OfferSelection.vue'

export default {
  name: 'QuizApp',
  components: {
    RegistrationForm,
    CaseStudy,
    OfferSelection
  },
  data() {
    return {
      currentStep: 'registration',
      userData: {}
    }
  },
  methods: {
    handleRegistration(data) {
      this.userData = data
      this.currentStep = 'caseStudy'
    },
    handleOfferSelection(offer) {
      // Handle next steps
    }
  }
}
</script> 